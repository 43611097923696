// utils/fetchWrapper.js
import { getCsrfToken, getAuthToken } from './securityUtils';
import { logout } from './auth';

// Store the original fetch function to avoid recursion
const originalFetch = window.fetch;

/**
 * Enhanced fetch wrapper with authentication, CSRF protection, and error handling
 * @param {string} url - URL to fetch
 * @param {Object} options - Fetch options
 * @returns {Promise<Response>} - Fetch response
 */
function fetchWrapper(url, options = {}) {
  // Determine the base URL based on environment
  const baseUrl = process.env.NODE_ENV === 'production' 
    ? '' // In production, use relative URLs
    : 'http://localhost:3000'; // In development, use localhost
    
  const newUrl = url.startsWith('http') ? url : baseUrl + url;
  
  // Initialize headers if not present
  if (!options.headers) {
    options.headers = {};
  }
  
  // Add CSRF token to non-GET requests in production mode
  if (options.method && options.method !== 'GET' && process.env.NODE_ENV === 'production') {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      options.headers['X-CSRF-Token'] = csrfToken;
    }
  }
  
  // Add authentication token if available
  const token = getAuthToken();
  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`;
  }
  
  // Ensure credentials are included for cookie handling
  options.credentials = 'include';
  
  // Execute the fetch and handle common errors
  return originalFetch(newUrl, options)
    .then(response => {
      // Check for authentication errors
      if (response.status === 401) {
        // Use the auth.js logout function to properly clear auth state
        logout(false); // Don't try to call the server again
        
        // Redirect to login if on admin page
        if (window.location.pathname.startsWith('/admin') && 
            window.location.pathname !== '/admin/login') {
          window.location.href = '/admin/login';
        }
      }
      
      // Check for permission errors
      if (response.status === 403) {
        console.error('Permission denied or CSRF validation failed');
        
        // If on admin page and not already on home, redirect to admin home
        if (window.location.pathname.startsWith('/admin') && 
            window.location.pathname !== '/admin/home' &&
            window.location.pathname !== '/admin/login') {
          window.location.href = '/admin/home';
        }
      }
      
      return response;
    })
    .catch(error => {
      console.error('Network or fetch error:', error);
      throw error;
    });
}

export default fetchWrapper;
