import { ref } from 'vue';
import jwtDecode from 'jwt-decode';
import { emit } from '@/utils/eventBus';
import { getAuthToken, setAuthToken, clearAuthToken, setCsrfToken } from '@/utils/securityUtils';

// Reactive state to track login status and user roles
const isAuthenticated = ref(false);
const isAdmin = ref(false);
const userRole = ref('visitor');
const userPermissions = ref([]);
const firstName = ref('');
const lastName = ref('');
const userId = ref(null);
const user = ref({});

// Token refresh timer
let tokenRefreshTimer = null;

/**
 * Verify if token is valid and not expired
 * @param {string} token - JWT token to check
 * @returns {boolean} - Whether token is valid and not expired
 */
function checkTokenExpiration(token) {
  if (!token) return false;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    
    // Check if token is about to expire (within 5 minutes)
    if (decoded.exp - currentTime < 300) {
      // Token is about to expire, schedule refresh
      scheduleTokenRefresh();
    }
    
    return decoded.exp > currentTime;
  } catch (error) {
    console.error('Error decoding token', error);
    return false;
  }
}

/**
 * Schedule token refresh before expiration
 */
function scheduleTokenRefresh() {
  // Clear any existing timer
  if (tokenRefreshTimer) {
    clearTimeout(tokenRefreshTimer);
  }
  
  // Set timer to refresh token 5 minutes before expiration
  tokenRefreshTimer = setTimeout(async () => {
    try {
      const response = await fetch('/api/auth/refresh-token', {
        method: 'POST',
        credentials: 'include'
      });
      
      if (response.ok) {
        const data = await response.json();
        if (data.token) {
          setAuthToken(data.token);
          
          // Update CSRF token if provided
          if (data.csrfToken) {
            setCsrfToken(data.csrfToken);
          }
          
          // Reinitialize auth state with new token
          initializeAuthState();
        }
      } else {
        // If refresh fails, log out the user
        logout(false); // Don't try to call the server
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      logout(false); // Don't try to call the server
    }
  }, 4 * 60 * 1000); // Refresh 4 minutes before expiration
}

/**
 * Initialize authentication state from stored token
 */
function initializeAuthState() {
  const token = getAuthToken();

  if (token && checkTokenExpiration(token)) {
    const decoded = parseJwt(token);
    
    if (decoded) {
      // Update reactive state
      isAuthenticated.value = true;
      isAdmin.value = decoded.isAdministrator || decoded.role === 'admin';
      userRole.value = decoded.role || (decoded.isAdministrator ? 'admin' : 'user');
      userPermissions.value = decoded.permissions || [];
      user.value = {
        email: decoded.email,
        firstName: decoded.firstName,
        lastName: decoded.lastName,
        isAdministrator: decoded.isAdministrator,
        role: decoded.role || (decoded.isAdministrator ? 'admin' : 'user'),
        permissions: decoded.permissions || [],
        userId: decoded.userId
      };
    }
  } else if (token) {
    // Only logout if there was a token that's now invalid
    logout(false); // Don't try to call the server
  } else {
    // Just clear the state without trying to call logout endpoint
    clearAuthState();
  }
}

/**
 * Parse JWT token and set user state
 * @param {string} token - JWT token to parse
 * @returns {Object|null} - Decoded token data or null if parsing fails
 */
function parseJwt(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const decoded = JSON.parse(jsonPayload);
    
    return decoded;
  } catch (e) {
    console.error('Error parsing JWT token:', e);
    return null;
  }
}

/**
 * Log in user with token
 * @param {string} token - JWT token
 * @param {Object} userData - Optional user data
 */
const login = (token, userData = null) => {
  setAuthToken(token);
  
  // If user data is provided, store it
  if (userData) {
    localStorage.setItem('user', JSON.stringify(userData));
  }
  
  initializeAuthState();
};

/**
 * Clear authentication state without server call
 */
const clearAuthState = () => {
  // Clear token refresh timer
  if (tokenRefreshTimer) {
    clearTimeout(tokenRefreshTimer);
    tokenRefreshTimer = null;
  }
  
  // Emit logout event for components
  emit('logout');
  
  // Clear authentication data
  clearAuthToken();
  localStorage.removeItem('user');
  localStorage.removeItem('csrfToken');
  
  // Reset reactive state
  isAuthenticated.value = false;
  isAdmin.value = false;
  userRole.value = 'visitor';
  userPermissions.value = [];
  firstName.value = '';
  lastName.value = '';
  userId.value = null;
  user.value = {};
};

/**
 * Log out user and clear authentication data
 * @param {boolean} callServer - Whether to call the server logout endpoint
 */
const logout = async (callServer = true) => {
  // Try to notify the server about logout
  if (callServer) {
    try {
      await fetch('/api/auth/logout', {
        method: 'POST',
        credentials: 'include'
      });
    } catch (error) {
      console.error('Logout request failed:', error);
    }
  }
  
  clearAuthState();
  
  // Emit an event that can be used for redirection
  emit('logoutComplete');
};

/**
 * Check if user has specific permission
 * @param {string} permission - Permission to check
 * @returns {boolean} - Whether user has the permission
 */
function hasPermission(permission) {
  if (!isAuthenticated.value) return false;
  
  // Admin users have all permissions
  if (isAdmin.value) return true;
  
  // Check if user has the specific permission
  return userPermissions.value && userPermissions.value.includes(permission);
}

/**
 * Check if user has specific role
 * @param {string|string[]} role - Role(s) to check
 * @returns {boolean} - Whether user has the role
 */
function hasRole(role) {
  if (!isAuthenticated.value) return false;
  
  if (Array.isArray(role)) {
    return role.includes(userRole.value);
  }
  
  return userRole.value === role;
}

// Initialize authentication state on load
initializeAuthState();

export { 
  isAuthenticated, 
  isAdmin,
  userRole,
  userPermissions,
  firstName, 
  lastName,
  userId,
  user,
  login,
  logout,
  hasPermission,
  hasRole
};
