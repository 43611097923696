import { createRouter, createWebHistory } from 'vue-router';
import { isAuthenticated, hasPermission } from '@/utils/auth';

//Website Routes
import SiteHome from '@/components/SiteHome.vue';
import SiteAbout from '@/components/SiteAbout.vue';
import SiteServices from '@/components/SiteServices.vue';
import SiteGallery from '@/components/SiteGallery.vue';
import SiteBookAnAppointment from '@/components/SiteBookAnAppointment.vue';
import SiteSchedule from '@/components/SiteSchedule.vue';
import SiteContact from '@/components/SiteContact.vue';

//Admin Routes
import AdminLogin from '@/components/admin/AdminLogin.vue';
import AdminHome from '@/components/admin/AdminHome.vue';
import AdminUsers from '@/components/admin/AdminUsers.vue';
import AdminPagesHome from '@/components/admin/AdminPagesHome.vue';
import AdminPagesAbout from '@/components/admin/AdminPagesAbout.vue';
import AdminPagesServices from '@/components/admin/AdminPagesServices.vue';
import AdminPagesServicesList from '@/components/admin/AdminPagesServicesList.vue';
import AdminPagesGallery from '@/components/admin/AdminPagesGallery.vue';
import AdminCalendarEvents from '@/components/admin/AdminCalendarEvents.vue';
import AdminCalendarBookings from '@/components/admin/AdminCalendarBookings.vue';
import AdminEditBooking from '@/components/admin/AdminEditBooking.vue';
import AdminMyCalendar from '@/components/admin/AdminMyCalendar.vue';

const routes = [
{
	path: '/',
	name: 'Home',
	component: SiteHome,
	meta: {
		title: 'ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg',
	},
},
{
	path: '/about',
	name: 'About',
	component: SiteAbout,
	meta: {
		title: 'About - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg alternate-bg',
	},
},
{
	path: '/services',
	name: 'Services',
	component: SiteServices,
	meta: {
		title: 'Services - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: '',
	},
},
{
	path: '/gallery',
	name: 'Gallery',
	component: SiteGallery,
	meta: {
		title: 'Gallery - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg alternate-bg',
	},
},
{
	path: '/bookanappointment',
	name: 'Book an Appointment',
	component: SiteBookAnAppointment,
	meta: {
		title: 'Book an Appointment - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg',
	},
},
{
	path: '/schedule',
	name: 'Schedule',
	component: SiteSchedule,
	meta: {
		title: 'Schedule - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg alternate-bg',
	},
},
{
	path: '/contact',
	name: 'Contact Us',
	component: SiteContact,
	meta: {
		title: 'Contact Us - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg',
	},
},
{
	path: '/admin/login',
	name: 'Admin Login',
	component: AdminLogin,
	meta: {
		title: 'Admin - Login - ENVY Image Studio',
		bodyClass: '',
	},
},
{
	path: '/admin/home',
	name: 'Admin Home',
	component: AdminHome,
	meta: {
		title: 'Admin - Home - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['view_admin_panel']
	},
},
{
	path: '/admin/pages_home',
	name: 'Admin Pages - Home',
	component: AdminPagesHome,
	meta: {
		title: 'Admin Pages - Home - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['edit_content']
	},
},
{
	path: '/admin/pages_about',
	name: 'Admin Pages - About',
	component: AdminPagesAbout,
	meta: {
		title: 'Admin Pages - About - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['edit_content']
	},
},
{
	path: '/admin/pages_services',
	name: 'Admin Pages - Services',
	component: AdminPagesServices,
	meta: {
		title: 'Admin Pages - Services - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['edit_services']
	},
},
{
	path: '/admin/pages_gallery',
	name: 'Admin Pages - Gallery',
	component: AdminPagesGallery,
	meta: {
		title: 'Admin Pages - Gallery - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['edit_gallery']
	},
},
{
	path: '/admin/pages_services_list',
	name: 'Admin Pages - Services List',
	component: AdminPagesServicesList,
	meta: {
		title: 'Admin Pages - Services List - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['edit_services']
	},
},
{
	path: '/admin/calendar_events',
	name: 'Admin Calendar - Manage Calendar Events',
	component: AdminCalendarEvents,
	meta: {
		title: 'Admin Calendar - Manage Calendar Events - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['manage_bookings']
	},
},
{
	path: '/admin/calendar_bookings',
	name: 'Admin Calendar - Manage Bookings',
	component: AdminCalendarBookings,
	meta: {
		title: 'Admin Calendar - Manage Bookings - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['manage_bookings']
	},
},
{
  path: '/admin/calendar_bookings/edit/:id',
  name: 'Admin Calendar - Edit Booking',
  component: AdminEditBooking,
  props: true,
  meta: {
		title: 'Admin Calendar - Manage Bookings - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['manage_bookings']
	},
},
{
  path: '/admin/calendar_show',
  name: 'Admin Calendar - My Calendar',
  component: AdminMyCalendar,
  meta: {
		title: 'Admin Calendar - My Calendar - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['view_bookings']
	},
},
{
	path: '/admin/users',
	name: 'Admin Users - Manage Site Users',
	component: AdminUsers,
	meta: {
		title: 'Admin - Users - ENVY Image Studio',
		bodyClass: '',
		requiresAuth: true,
		requiredPermissions: ['manage_users']
	},
}];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		// Always scroll to the top of the page
		return { top: 0 };
	},
});

router.beforeEach((to, from, next) => {
	// Set the document title
	document.title = to.meta.title || 'ENVY Image Studio - Nadezda Vlasova';
	
	// Set the body class
	document.body.className = to.meta.bodyClass || '';
	
	// Check authentication and permission requirements
	if (to.meta.requiresAuth) {
		// Check if user is authenticated
		if (!isAuthenticated.value) {
			// Redirect to login if not authenticated
			return next('/admin/login');
		}
		
		// Check permission-based access
		if (to.meta.requiredPermissions && to.meta.requiredPermissions.length > 0) {
			const hasRequiredPermission = to.meta.requiredPermissions.some(permission => 
				hasPermission(permission)
			);
			
			if (!hasRequiredPermission) {
				// If user doesn't have required permissions, redirect to appropriate page
				if (hasPermission('view_bookings')) {
					// Users with view_bookings permission can access the calendar page
					return next('/admin/calendar_show');
				} else if (hasPermission('view_admin_panel')) {
					// Users with view_admin_panel permission can access the admin home
					return next('/admin/home');
				} else {
					// For any other unauthorized access, go to login
					return next('/admin/login');
				}
			}
		}
	}
	
	// If route is admin root, redirect to login
	if (to.path === '/admin' || to.path === '/admin/') {
		return next('/admin/login');
	}
	
	// If route is login and user is already authenticated
	if (to.path === '/admin/login' && isAuthenticated.value) {
		return next('/admin/home');
	}

	next();
});

export default router;