<template>
  <div class="admin-users">
    <h1>User Management</h1>
    
    <div v-if="loading" class="text-center my-5">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    
    <div v-else>
      <!-- User List -->
      <div class="card mb-4">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Users</h5>
          <button class="btn btn-primary btn-sm" @click="showAddUserModal">Add User</button>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Last Login</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in users" :key="user._id">
                  <td>{{ user.firstName }} {{ user.lastName }}</td>
                  <td>{{ user.email }}</td>
                  <td>
                    <span class="badge" :class="getRoleBadgeClass(user)">
                      {{ user.role || (user.isAdministrator ? 'Admin' : 'User') }}
                    </span>
                  </td>
                  <td>
                    <span class="badge" :class="getStatusBadgeClass(user)">
                      {{ user.accountStatus || 'Active' }}
                    </span>
                  </td>
                  <td>{{ formatDate(user.lastLogin) }}</td>
                  <td>
                    <button class="btn btn-sm btn-outline-primary me-1" @click="editUser(user)">
                      <i class="bi bi-pencil"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-danger" @click="confirmDeleteUser(user)">
                      <i class="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Add/Edit User Modal -->
    <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="userModalLabel">{{ editMode ? 'Edit User' : 'Add User' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="saveUser">
              <div class="mb-3">
                <label for="firstName" class="form-label">First Name</label>
                <input type="text" class="form-control" id="firstName" v-model="formData.firstName" required>
              </div>
              <div class="mb-3">
                <label for="lastName" class="form-label">Last Name</label>
                <input type="text" class="form-control" id="lastName" v-model="formData.lastName" required>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input type="email" class="form-control" id="email" v-model="formData.email" required>
              </div>
              <div class="mb-3" v-if="!editMode">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" id="password" v-model="formData.password" required>
              </div>
              <div class="mb-3">
                <label for="role" class="form-label">Role</label>
                <select class="form-select" id="role" v-model="formData.role">
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Permissions</label>
                <div class="form-check" v-for="permission in availablePermissions" :key="permission.value">
                  <input class="form-check-input" type="checkbox" 
                         :id="'permission-' + permission.value" 
                         :value="permission.value" 
                         v-model="formData.permissions">
                  <label class="form-check-label" :for="'permission-' + permission.value">
                    {{ permission.label }}
                  </label>
                </div>
              </div>
              <div class="mb-3">
                <label for="accountStatus" class="form-label">Account Status</label>
                <select class="form-select" id="accountStatus" v-model="formData.accountStatus">
                  <option value="active">Active</option>
                  <option value="suspended">Suspended</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="saveUser">Save</button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Delete Confirmation Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Confirm Delete</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete user <strong>{{ selectedUser?.email }}</strong>?
            This action cannot be undone.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-danger" @click="deleteUser">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetchWrapper from '@/utils/fetchWrapper';
import * as bootstrap from 'bootstrap';

export default {
  name: 'AdminUsers',
  data() {
    return {
      users: [],
      loading: true,
      editMode: false,
      selectedUser: null,
      userModal: null,
      deleteModal: null,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: 'user',
        permissions: [],
        accountStatus: 'active'
      },
      availablePermissions: [
        { value: 'view_admin_panel', label: 'View Admin Panel' },
        { value: 'edit_content', label: 'Edit Content' },
        { value: 'manage_users', label: 'Manage Users' },
        { value: 'manage_bookings', label: 'Manage Bookings' },
        { value: 'view_bookings', label: 'View Bookings' },
        { value: 'edit_services', label: 'Edit Services' },
        { value: 'edit_gallery', label: 'Edit Gallery' }
      ]
    };
  },
  mounted() {
    this.fetchUsers();
    this.userModal = new bootstrap.Modal(document.getElementById('userModal'));
    this.deleteModal = new bootstrap.Modal(document.getElementById('deleteModal'));
  },
  methods: {
    async fetchUsers() {
      this.loading = true;
      try {
        const response = await fetchWrapper('/api/users');
        if (response.ok) {
          const data = await response.json();
          this.users = data.users;
        } else {
          console.error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        this.loading = false;
      }
    },
    showAddUserModal() {
      this.editMode = false;
      this.formData = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: 'user',
        permissions: ['view_bookings'],
        accountStatus: 'active'
      };
      this.userModal.show();
    },
    editUser(user) {
      this.editMode = true;
      this.selectedUser = user;
      this.formData = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role || (user.isAdministrator ? 'admin' : 'user'),
        permissions: user.permissions || [],
        accountStatus: user.accountStatus || 'active'
      };
      this.userModal.show();
    },
    async saveUser() {
      try {
        let response;
        
        // Set isAdministrator based on role for backward compatibility
        const userData = {
          ...this.formData,
          isAdministrator: this.formData.role === 'admin'
        };
        
        if (this.editMode) {
          response = await fetchWrapper(`/api/auth/users/${this.selectedUser._id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userData)
          });
        } else {
          response = await fetchWrapper('/api/auth/users', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userData)
          });
        }
        
        if (response.ok) {
          await this.fetchUsers();
          this.userModal.hide();
        } else {
          const error = await response.json();
          alert(error.message || 'Failed to save user');
        }
      } catch (error) {
        console.error('Error saving user:', error);
        alert('An error occurred while saving the user');
      }
    },
    confirmDeleteUser(user) {
      this.selectedUser = user;
      this.deleteModal.show();
    },
    async deleteUser() {
      try {
        const response = await fetchWrapper(`/api/auth/users/${this.selectedUser._id}`, {
          method: 'DELETE'
        });
        
        if (response.ok) {
          await this.fetchUsers();
          this.deleteModal.hide();
        } else {
          const error = await response.json();
          alert(error.message || 'Failed to delete user');
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('An error occurred while deleting the user');
      }
    },
    formatDate(date) {
      if (!date) return 'Never';
      return new Date(date).toLocaleString();
    },
    getRoleBadgeClass(user) {
      const role = user.role || (user.isAdministrator ? 'admin' : 'user');
      return {
        'bg-danger': role === 'admin',
        'bg-primary': role === 'user'
      };
    },
    getStatusBadgeClass(user) {
      const status = user.accountStatus || 'active';
      return {
        'bg-success': status === 'active',
        'bg-warning': status === 'suspended',
        'bg-secondary': status === 'inactive'
      };
    }
  }
};
</script>

<style scoped>
.admin-users {
  padding: 20px;
}
</style>