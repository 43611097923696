<template>
<div class="row justify-content-center">

	<div class="col-lg-6 col-md-6">

		<div class="card o-hidden border-0 shadow-lg my-5">
			<div class="card-body p-0">
				<!-- Nested Row within Card Body -->
				<div class="row text-center align-items-center">
					<div class="col-lg-6">
            <img src="@/assets/admin/images/logo_admin.png" alt="ENVY Image Studio Logo" id="nav-logo">
          </div>
					<div class="col-lg-6">
						<div class="p-5">
							<div class="text-center">
								<h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
							</div>
							<form class="user" @submit.prevent="login">
								<div class="form-group">
									<input v-model="form.email" type="email" class="form-control form-control-user"
										id="email" aria-describedby="emailHelp"
										placeholder="Email Address" required>
                  <div v-if="validationErrors.email" class="text-danger small">{{ validationErrors.email }}</div>
								</div>
								<div class="form-group">
									<input v-model="form.password" type="password" class="form-control form-control-user"
										id="password" autocomplete="current-password" placeholder="Password" required>
                  <div v-if="validationErrors.password" class="text-danger small">{{ validationErrors.password }}</div>
								</div>

								<button type="submit" class="btn btn-primary btn-user btn-block" :disabled="isLoading">
                  {{ isLoading ? 'Logging in...' : 'Login' }}
                </button>
								<div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { validateEmail } from '@/utils/securityUtils';
import { setCsrfToken } from '@/utils/securityUtils';
import fetchWrapper from '@/utils/fetchWrapper';
import { login, hasPermission } from '@/utils/auth';

export default {
  name: 'AdminLogin',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      validationErrors: {
        email: '',
        password: ''
      },
      errorMessage: '',
      isLoading: false,
      csrfToken: ''
    };
  },
  methods: {
    validateForm() {
      let isValid = true;
      this.validationErrors = {
        email: '',
        password: ''
      };
      
      // Email validation
      if (!this.form.email) {
        this.validationErrors.email = 'Email is required';
        isValid = false;
      } else if (!validateEmail(this.form.email)) {
        this.validationErrors.email = 'Please enter a valid email address';
        isValid = false;
      }
      
      // Password validation
      if (!this.form.password) {
        this.validationErrors.password = 'Password is required';
        isValid = false;
      }
      
      return isValid;
    },
    
    async login() {
      this.errorMessage = '';
      
      // Client-side validation
      if (!this.validateForm()) {
        return;
      }
      
      this.isLoading = true;
      
      try {
        // Make the login request directly without CSRF token in development
        const response = await fetchWrapper('/api/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: this.form.email,
            password: this.form.password
          })
        });

        // Handle non-OK responses
        if (!response.ok) {
          const data = await response.json();
          this.errorMessage = data.message || 'Login failed. Please check your credentials.';
          this.isLoading = false;
          return;
        }

        const data = await response.json();
        
        // Initialize authentication state using the auth.js login function
        if (data.token) {
          login(data.token, data.user);
          
          // Check if user has permission to access admin panel
          if (!hasPermission('view_admin_panel')) {
            this.errorMessage = 'You do not have permission to access the admin panel.';
            this.isLoading = false;
            return;
          }
        }
        
        // Store CSRF token for future requests if provided
        if (data.csrfToken) {
          setCsrfToken(data.csrfToken);
        }
        
        // Navigate to the admin panel after login
        this.$router.push('/admin/home');
      } catch (error) {
        this.errorMessage = 'An error occurred during login. Please try again later.';
        console.error('Login error:', error);
      } finally {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    // Check if user is already authenticated
    const token = localStorage.getItem('token');
    if (token) {
      // Verify token validity with the server
      fetchWrapper('/api/auth/verify-token', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.ok) {
          // Check if user has permission to access admin panel
          if (hasPermission('view_admin_panel')) {
            this.$router.push('/admin/home');
          } else {
            // Clear token if user doesn't have permission
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('csrfToken');
            this.errorMessage = 'You do not have permission to access the admin panel.';
          }
        } else {
          // Clear invalid token
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('csrfToken');
        }
      })
      .catch(error => {
        console.error('Token verification error:', error);
        // Handle network errors
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('csrfToken');
      });
    }
  }
};
</script>