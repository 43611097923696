<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Manage Events</h1>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <a href="#collapseNewEvent" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseNewEvent">
            <h6 class="m-0 font-weight-bold text-primary">Create New Event</h6>
          </a>
          <div class="collapse" id="collapseNewEvent">
            <div class="card-body">
              <form @submit.prevent="createEvent">
                <div class="form-group">
                  <label for="eventName">Event Name *</label>
                  <input v-model="newEvent.eventName" id="eventName" class="form-control" type="text" required />
                </div>
                <div class="form-group">
                  <label for="locationName">Location Name</label>
                  <input v-model="newEvent.locationName" id="locationName" class="form-control" type="text" />
                </div>
                <div class="form-group">
                  <label for="address">Address</label>
                  <input v-model="newEvent.address" id="address" class="form-control" type="text" />
                </div>
                <div class="form-group">
                  <label for="city">City *</label>
                  <input v-model="newEvent.city" id="city" class="form-control" type="text" required />
                </div>
                <div class="form-group">
                  <label for="state">State</label>
                  <select v-model="newEvent.state" id="state" class="form-control">
                    <option v-for="state in usStates" :key="state" :value="state">{{ state }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="eventStartDate">Event Start Date *</label>
                  <input v-model="newEvent.eventStartDate" id="eventStartDate" class="form-control" type="date" required />
                </div>
                <div class="form-group">
                  <label for="eventEndDate">Event End Date *</label>
                  <input v-model="newEvent.eventEndDate" id="eventEndDate" class="form-control" type="date" required />
                  <br />
                  <label>* - Required Field</label>
                </div>
                <div class="form-group">
                  <div class="text-right">
                    <button type="submit" class="btn btn-success btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-check"></i>
                      </span>
                      <span class="text">Create Event</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow mb-4">
          <a href="#collapseUpcomingEvents" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseUpcomingEvents">
            <h6 class="m-0 font-weight-bold text-primary">Upcoming Events</h6>
          </a>
          <div class="collapse" id="collapseUpcomingEvents">
            <div class="card-body">
              <label>Schedule Calendar Settings</label>
              <div class="alert alert-primary" role="alert">
                Turning this setting on will display the upcoming events on the site (Schedule Page) automatically. The number of events is defined below.
              </div>
              <form @submit.prevent="saveEventSettingsHandler">
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" id="autoDisplay" v-model="calendarSettings.autoDisplay">
                  <label class="form-check-label" for="autoDisplay">Automatically display the next</label>
                  <input v-model="calendarSettings.numUpcomingEvents" class="form-control-inline ml-2" type="number" min="1" />
                  <span> events on the website.</span>
                </div>
                <div class="form-group">
                  <div class="text-right">
                    <br />
                    <button type="submit" class="btn btn-success btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-check"></i>
                      </span>
                      <span class="text">Save</span>
                    </button>
                    <br />
                    <span v-if="contentSaved" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Changes Saved</span>
                    <span v-if="contentError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Error Saving</span>
                  </div>
                </div>
              </form>

              <label>Upcoming Events List</label>
              <div class="alert alert-primary" role="alert">
                To manually define which events to display on the website. Uncheck the checkbox above, then check the checkboxes in the 'Show Event' column for the events you want to display.
              </div>
              <DataTable 
                :columns="columnsUpcoming"
                :options="optionsUpcoming"
                :data="upcomingEvents"
                class="display nowrap"
                width="100%"
                @draw="initUpcomingEvent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow mb-4">
          <a href="#collapsePastEvents" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapsePastEvents" ref="refPastEvents">
            <h6 class="m-0 font-weight-bold text-primary">Past Event</h6>
          </a>
          <div class="collapse" id="collapsePastEvents">
            <div class="card-body">
              <DataTable 
                :columns="columnsPast"
                :options="optionsPast"
                :data="pastEvents"
                class="display nowrap"
                width="100%" 
                @draw="initPastEvent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net';
import 'datatables.net-responsive';
import moment from 'moment';

import { fetchStates, fetchUpcomingEvents, fetchPastEvents, createEvent, fetchCalendarSettings, saveCalendarSettings, updateEventShowStatus, deleteEventById } from '@/utils/events';


DataTable.use(DataTablesLib);

export default {
  name: 'AdminCalendarEvents',
  components: {
    DataTable
  },
  data() {
    return {
      newEvent: {
        eventName: '',
        locationName: '',
        address: '',
        city: '',
        state: '',
        eventStartDate: '',
        eventEndDate: '',
      },
      calendarSettings: {
        autoDisplay: true,
        numUpcomingEvents: 1
      },
      upcomingEvents: [],
      pastEvents: [],
      usStates: [],
      columnsUpcoming: [
        { title: 'Event Name', data: 'eventName' },
        { title: 'Location Name', data: 'locationName' },
        { title: 'Address', data: 'address' },
        { title: 'City', data: 'city' },
        { title: 'State', data: 'state' },
        { 
          title: 'Event Start Date', 
          data: 'eventStartDate', 
          render: (data) => `${moment.utc(data).format('MMMM Do')}`
        },
        { 
          title: 'Event End Date', 
          data: 'eventEndDate',
          render: (data) => `${moment.utc(data).format('MMMM Do')}`
        },
        { 
          title: 'Show Event', 
          data: 'showEvent', 
          render: (data, type, row) => `<input type="checkbox" ${data ? 'checked' : ''} onchange="window.updateShowEvent('${row._id}', this.checked)" />` 
        },
        { // Add Delete Button Column
          title: 'Actions', 
          data: '_id', 
          render: (data) => `<button class="btn btn-danger" onclick="window.deleteEvent('${data}')">Delete</button>`
        }
      ],
      optionsUpcoming: {
        dom: 'Bftip',
        responsive: true,
        order: [[5, "asc"]]
      },
      columnsPast: [
        { title: 'Event Name', data: 'eventName' },
        { title: 'Location Name', data: 'locationName' },
        { title: 'Address', data: 'address' },
        { title: 'City', data: 'city' },
        { title: 'State', data: 'state' },
        { 
          title: 'Event Start Date', 
          data: 'eventStartDate', 
          render: (data) => `${moment.utc(data).format('MMMM Do')}`
        },
        { 
          title: 'Event End Date', 
          data: 'eventEndDate',
          render: (data) => `${moment.utc(data).format('MMMM Do')}`
        },
        { // Add Delete Button Column
          title: 'Actions', 
          data: '_id', 
          render: (data) => `<button class="btn btn-danger" onclick="window.deleteEvent('${data}')">Delete</button>`
        }
      ],
      optionsPast: {
        dom: 'Bftip',
        responsive: true,
        order: [[5, "desc"]]
      },
      contentSaved: false,
      contentError: false,
      dtObj: {
        upcoming: {},
        past: {},
      },
    };
  },
  async created() {
    await this.loadStates();
    await this.loadEvents();
    await this.loadCalendarSettings();
    window.updateShowEvent = this.updateShowEvent;
  },
  methods: {
    async loadStates() {
      this.usStates = await fetchStates();
    },
    async loadEvents() {
      const { autoDisplay, numberOfEvents } = await fetchCalendarSettings();
      
      this.upcomingEvents = await fetchUpcomingEvents();
      this.pastEvents = await fetchPastEvents();
      this.autoDisplay = autoDisplay;
      this.numUpcomingEvents = numberOfEvents;
    },
    async loadCalendarSettings() {
      const settings = await fetchCalendarSettings();
      
      if(settings != null && settings.autoDisplay != null && settings.numUpcomingEvents != null) {
        this.calendarSettings.autoDisplay = settings.autoDisplay;
        this.calendarSettings.numUpcomingEvents = settings.numUpcomingEvents;
      }
    },
    async createEvent() {
      await createEvent(this.newEvent);
      this.loadEvents();
      this.resetNewEvent();
    },
    async saveEventSettingsHandler() {
      try{
        await saveCalendarSettings({
          autoDisplay: this.calendarSettings.autoDisplay,
          numUpcomingEvents: this.calendarSettings.numUpcomingEvents,
        }, this.setContentSaved, this.setContentError);
      } catch (error) {
        console.error('Error updating event show status:', error);
        this.setContentError();
      }
    },
    async updateShowEvent(eventId, showEvent) {
      try {
        await updateEventShowStatus(eventId, showEvent);
        console.log('Event show status updated successfully');
      } catch (error) {
        console.error('Error updating event show status:', error);
      }
    },
    async deleteEvent(eventId) {
      if (confirm('Are you sure you want to delete this event?')) {
        try {
          await deleteEventById(eventId);  // You'll need to implement this API call in your utils/events.js
          this.loadEvents();  // Reload the events after deletion
        } catch (error) {
          console.error('Error deleting event:', error);
        }
      }
    },
    setContentSaved() {
      this.contentSaved = true;
      this.contentError = false;
      setTimeout(() => {
        this.contentSaved = false;
      }, 5000); 
    },
    setContentError() {
      this.contentError = true;
      this.contentSaved = false;
      setTimeout(() => {
        this.contentError = false;
      }, 5000); 
    },
    resetNewEvent() {
      this.newEvent = {
        eventName: '',
        locationName: '',
        address: '',
        city: '',
        state: '',
        country: '',
        eventStartDate: '',
        eventEndDate: '',
      };
    },
    redrawTable(args) {

      args.dt.trigger('responsive-resize', {
        e: args.event,
        datatable: args.dt,
        columns: [true, true, true, true, true, true, false],
      });

      args.dt.trigger('column-sizing', {
        e: args.event, 
        settings: args.settings, 
      });
    },
    initUpcomingEvent(ev, settings) {
      this.dtObj.upcoming.event = ev.event;
      this.dtObj.upcoming.dt = ev.event.dt;
      this.dtObj.upcoming.settings = settings;
    },
    initPastEvent(ev, settings) {
      this.dtObj.past.event = ev.event;
      this.dtObj.past.dt = ev.event.dt;
      this.dtObj.past.settings = settings;
    },
  },
  mounted() {
    //This is a bit hackish but the only way i found to redraw the DataTable which fixes the mobile/responsive issues
    const collapseUpcomingElement = document.getElementById('collapseUpcomingEvents');
    const collapsePastElement = document.getElementById('collapsePastEvents');

    // Callback function to execute when mutations are observed
    const callback = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const targetElement = mutation.target;

          if (targetElement.classList.contains('show')) {
            if (targetElement === collapseUpcomingElement) {
              this.redrawTable(this.dtObj.upcoming);
            } else if (targetElement === collapsePastElement) {
              this.redrawTable(this.dtObj.past);
            }
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target element for configured mutations
    observer.observe(collapseUpcomingElement, { attributes: true, attributeFilter: ['class'] });
    observer.observe(collapsePastElement, { attributes: true, attributeFilter: ['class'] });

    // Store observer reference for cleanup
    this.observer = observer;
    
    window.deleteEvent = this.deleteEvent;
  },
  beforeUnmount() {
    delete window.updateShowEvent;
    delete window.deleteEvent;

    // Disconnect the observer when the component is unmounted
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
</script>

<style scoped>
@import 'datatables.net-dt/css/dataTables.dataTables.min.css';
@import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
.form-control-inline {
  display: inline-block;
  width: 60px;
  margin-left: 10px;
}
</style>
