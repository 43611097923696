<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

      <!-- Sidebar - Brand -->
      <router-link to="/admin/home" class="sidebar-brand d-flex align-items-center justify-content-center">
        <div class="sidebar-brand-text mx-3">ENVY Image Studio</div>
      </router-link>
      
      <div v-if="loggedIn">
        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Dashboard -->
        <li :class="{ active: $route.path === '/admin/home' }" class="nav-item">
          <router-link class="nav-link" to="/admin/home">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </router-link>
        </li>

        <!-- Divider -->
        <hr v-if="hasPermission('edit_content')" class="sidebar-divider">

        <!-- Heading -->
        <div v-if="hasPermission('edit_content')" class="sidebar-heading">
          Pages
        </div>

        <!-- Nav Item - Home -->
        <li v-if="hasPermission('edit_content')" :class="{ active: $route.path === '/admin/pages_home' }" class="nav-item">
          <router-link class="nav-link" to="/admin/pages_home">
            <i class="fas fa-home"></i>
            <span>Home</span>
          </router-link>
        </li>
        
        <!-- Nav Item - About -->
        <li v-if="hasPermission('edit_content')" :class="{ active: $route.path === '/admin/pages_about' }" class="nav-item">
          <router-link class="nav-link" to="/admin/pages_about">
            <i class="far fa-file"></i>
            <span>About</span>
          </router-link>
        </li>
        
        <!-- Nav Item - Services -->
        <li v-if="hasPermission('edit_services')" :class="{ active: $route.path === '/admin/pages_services' }" class="nav-item">
          <router-link class="nav-link" to="/admin/pages_services">
            <i class="fas fa-concierge-bell"></i>
            <span>Services</span>
          </router-link>
        </li>
        
        <li v-if="hasPermission('edit_services')" :class="{ active: $route.path === '/admin/pages_services_list' }" class="nav-item">
          <router-link class="nav-link" to="/admin/pages_services_list">
            <i class="fas fa-concierge-bell"></i>
            <span>Services List</span>
          </router-link>
        </li>
        
        <!-- Nav Item - Gallery -->
        <li v-if="hasPermission('edit_gallery')" :class="{ active: $route.path === '/admin/pages_gallery' }" class="nav-item">
          <router-link class="nav-link" to="/admin/pages_gallery">
            <i class="far fa-images"></i>
            <span>Gallery</span>
          </router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider">

        <!-- Heading -->
        <div class="sidebar-heading">
          Calendar
        </div>

        <!-- Nav Item - Manage Events -->
        <li v-if="hasPermission('manage_bookings')" :class="{ active: $route.path === '/admin/calendar_events' }" class="nav-item">
          <router-link class="nav-link" to="/admin/calendar_events">
            <i class="fas fa-city"></i>
            <span>Manage Events</span>
          </router-link>
        </li>
        
        <!-- Nav Item - Manage Bookings -->
        <li v-if="hasPermission('manage_bookings')" :class="{ active: $route.path === '/admin/calendar_bookings' }" class="nav-item">
          <router-link class="nav-link" to="/admin/calendar_bookings">
            <i class="fas fa-book"></i>
            <span>Manage Bookings</span>
          </router-link>
        </li>

        <!-- Nav Item - My Calendar -->
        <li v-if="hasPermission('view_bookings')" :class="{ active: $route.path === '/admin/calendar_show' }" class="nav-item">
          <router-link class="nav-link" to="/admin/calendar_show">
            <i class="far fa-calendar-check"></i>
            <span>My Calendar</span>
          </router-link>
        </li>

        <!-- Divider -->
        <hr v-if="hasPermission('manage_users')" class="sidebar-divider">

        <!-- Heading -->
        <div v-if="hasPermission('manage_users')" class="sidebar-heading">
          Site Configuration
        </div>
        
        <!-- Nav Item - Users -->
        <li v-if="hasPermission('manage_users')" :class="{ active: $route.path === '/admin/users' }" class="nav-item">
          <router-link class="nav-link" to="/admin/users">
            <i class="fas fa-users"></i>
            <span>Users</span>
          </router-link>
        </li>
      </div>
    </ul>
</template>

<script>
import { computed } from 'vue';
import { isAuthenticated, isAdmin, hasPermission } from '@/utils/auth';

export default {
  name: 'AdminLeftNav',
  setup() {
    const loggedIn = computed(() => isAuthenticated.value);
    const isAdminStatus = computed(() => isAdmin.value);

    return {
      loggedIn,
      isAdmin: isAdminStatus,
      hasPermission
    };
  },
}
</script>

<style>
@media (max-width: 576px) { 
  #accordionSidebar {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar#accordionSidebar {
      width: 6.5rem !important;
  }
  .sidebar#accordionSidebar .nav-item .nav-link {
    text-align: center !important;
    padding: 0.75rem 1rem !important;
    width: 6.5rem !important;
    display: block !important;
  }
  .sidebar#accordionSidebar .nav-item .nav-link i {
    font-size: 1rem !important;
    margin-right: 0 !important;
  }
  .sidebar#accordionSidebar .nav-item .nav-link span {
    font-size: 0.65rem !important;
    display: block !important;
  }
  .sidebar#accordionSidebar .sidebar-heading {
    text-align: center !important;
  }
}

@media (min-width: 992px) { 
  .sidebar#accordionSidebar {
      width: 14rem !important;
  }
  
  .sidebar#accordionSidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1rem;
    width: 14rem;
  }
  .sidebar#accordionSidebar .nav-item .nav-link i {
    font-size: 0.85rem;
    margin-right: 0.25rem;
  }
  .sidebar#accordionSidebar .nav-item .nav-link span {
    font-size: 0.85rem;
    display: inline;
  }
  .sidebar#accordionSidebar .sidebar-heading {
    text-align: left;
  }
}
</style>