<template>
  <section class="hero d-flex align-items-center text-white">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h1><span class="highlight" v-html="aboutData.nadezdaName"></span></h1>
          <h5 v-if="aboutData.nadezdaSubtitle" v-html="aboutData.nadezdaSubtitle"></h5>
          <p v-if="aboutData.nadezdaDescription" v-html="aboutData.nadezdaDescription"></p>
        </div>
      </div>
    </div>
  </section>

  <section class="text-white">
    <div class="container">
      <div class="row align-items-center">
        <div class="align-self-start col-md-6 mb-4 mb-md-0">
          <img src="@/assets/images/nadya_model_side.png" alt="Nadya Model" class="img-fluid">
        </div>
        <div class="col-md-6 page-text" v-html="aboutData.nadezdaBio"></div>
      </div>
    </div>
  </section>

  <section class="hero py-3 d-flex align-items-center text-white">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h2><span class="highlight" v-html="aboutData.valeriiaName"></span></h2>
          <h5 v-if="aboutData.valeriiaSubtitle" v-html="aboutData.valeriiaSubtitle"></h5>
          <p v-if="aboutData.valeriiaDescription" v-html="aboutData.valeriiaDescription"></p>
        </div>
      </div>
    </div>
  </section>

  <section class="text-white">
    <div class="container">
      <div class="row align-items-center">
        <div class="align-self-start col-md-6 mb-4 mb-md-0">
          <img src="@/assets/images/gallery/valeriia_belozerova-new.png" alt="Valeriia Belozerova" class="img-fluid">
        </div>
        <div class="col-md-6 page-text" v-html="aboutData.valeriiaBio"></div>
      </div>
    </div>
  </section>
  
  <section class="hero py-3 d-flex align-items-center text-white">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h2><span class="highlight" v-html="aboutData.mariaName"></span></h2>
          <h5 v-if="aboutData.mariaSubtitle" v-html="aboutData.mariaSubtitle"></h5>
          <p v-if="aboutData.mariaDescription" v-html="aboutData.mariaDescription"></p>
        </div>
      </div>
    </div>
  </section>

  <section class="text-white">
    <div class="container">
      <div class="row align-items-center">
        <div class="align-self-start col-md-6 mb-4 mb-md-0">
          <img src="@/assets/images/gallery/maria-about.png" alt="Maria Oblakova" class="img-fluid">
        </div>
        <div class="col-md-6 page-text" v-html="aboutData.mariaBio"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SiteAbout',
  data() {
    return {
      aboutData: ''
    };
  },
  created() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch('/api/content/page_about');
        const data = await response.json();
        if (data && data.content) {
          this.aboutData = JSON.parse(data.content);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
        this.aboutData = '';
      }
    }
  }
}
</script>